export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            if (!IRISCollectionCustomer.isEcomodeActive) {
                import(
                    '@scripts/components/front-page/sliders.component' /* webpackChunkName: "scripts/sliders.component" */
                ).then(({ default: SlidersComponent }) => {
                    new SlidersComponent()
                })

                import(
                    '@scripts/components/front-page/animations.component' /* webpackChunkName: "scripts/animations.component" */
                ).then(({ default: AnimationsComponent }) => {
                    new AnimationsComponent()
                })
            }
        }
    }
}
